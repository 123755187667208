<template>
  <div>
    <el-form ref="data" :model="obj" label-width="80px">
      <el-form-item label="奶茶名称">
        <el-input v-model="obj.title"></el-input>
      </el-form-item>
      <el-form-item label="奶茶原料">
        <el-input v-model="obj.yuanliao"></el-input>
      </el-form-item>
      <el-form-item label="奶茶金额">
        <el-input v-model="obj.money"></el-input>
      </el-form-item>
      <el-form-item label="奶茶类型">
        <template>
          <el-radio-group v-model="obj.fenlei_id">
            <el-radio :label="item.fenlei_id" v-for="(item, index) in classList" :key="index">{{item.text}}</el-radio>
          </el-radio-group>
        </template>
      </el-form-item>
      <el-form-item label="奶茶图片">
        <el-upload
          class="avatar-uploader"
           action="http://127.0.0.1:5000/upload"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :before-upload="uploadBefore"
          accept="image/*"
        >
          <img v-if="imgUrl" :src="imgUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addMilktea">确认添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classList: [],
	  newUrl:'',
      obj: {
        img: "",
        title: "",
        yuanliao: "",
        fenlei_id: "",
        money: ""
      },
      imgUrl: ""
    };
  },
  created() {
    this.getClassList();
	this.updateUrl()
	this.$store.dispatch('updateServerUrl', this.newUrl);
  },
  methods: {
	  updateUrl() {
	        this.$store.dispatch('updateServerUrl', this.newUrl);
	      },
    getClassList() {
      // 从服务器获取奶茶分类列表，赋值给classList
    },
    uploadSuccess(res, file) {
      this.imgUrl = URL.createObjectURL(file.raw);
      this.obj.img = res.imgUrl;
    },
    uploadBefore(file) {
      // 图片上传之前的操作，如限制图片大小等
      return true;
    },
    addMilktea() {
      // 将obj中的奶茶信息提交给服务器进行添加操作
    }
  }
};
</script>

<style>
.avatar-uploader {
  width: 200px;
  height: 200px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #999;
  line-height: 200px;
}
.avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>